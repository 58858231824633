import { Component, Ref, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { ValidationObserver } from 'vee-validate'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import IntegrationInfo from '@/modules/common/components/mixins/integrationInfo'
import { mapState } from 'vuex'
@Component({
  name: 'GtrEventNewSyncView',
  computed: {
    ...mapState('syncs', ['syncs']),
    ...mapState('event', ['participant_fields', 'session_fields']),
    ...mapState('security', ['zapierKeys'])
  }
})
export default class GtrEventNewSyncView extends mixins(GtrSuper, IntegrationInfo) {
  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>;

  @Ref()
  readonly zapier!: InstanceType<typeof HTMLDivElement>;

  zapierKeys!: any;

  data () {
    // defined below
    // allows data to be reset if modal is closed
    return this.initialState()
  }

  initialState () {
    return {
      current_sync_data: {
        type: null,
        send_welcome_email: 0,
        base_update_on: null,
        sync_map: [],
        sync_fields: {}
	    },
      push_or_pull: '',
      table: '',
      all_sync_hash: {
        PUSH: {
          participants: {},
          sessions: {},
          session_attendees: {},
          session_accesses: {}
        },
        PULL: {
          participants: {},
          sessions: {},
          session_attendees: {},
          session_accesses: {}
        }
      },
      participant_fields_items_for_select: [],
      session_fields_items_for_select: [],
      current_sync_table: '',
      dialog: false,
      modalIndex: 0,
      nextBtnTxt: 'Next',
      currentIntegrationName: null,
      currentIntegrationLogo: null,
      currentIntegrationAlt: null,
      tableOptions: [
        {
          label: 'Participants',
          value: 'participants'
        },
        {
          label: 'Sessions',
          value: 'sessions'
        },
        {
          label: 'Session Attendees',
          value: 'session_attendees'
        }
      ],
      // TODO: Flag for deletion.
      // uniqueIdentifier: [
      //   {
      //     label: 'Email',
      //     value: 'email'
      //   },
      //   {
      //     label: 'External ID',
      //     value: 'external_id'
      //   }
      // ],
      sendWelcomeEmail: false,
      integrationType: null
    }
  }

  async mounted () {
	  await this.$store.dispatch('syncs/getSyncs')
    await this.$store.dispatch('event/getParticipantFields', this.$route.params.event_uuid)
    await this.$store.dispatch('event/getSessionFields', this.$route.params.event_uuid)
    await this.$store.dispatch('security/getZapierKeys')
  }

  openModal (integrationName, integrationLogo, integrationAlt) {
    this.$data.currentIntegrationName = integrationName
    this.$data.currentIntegrationLogo = integrationLogo
    this.$data.currentIntegrationAlt = integrationAlt

    if (integrationName === 'zapier') {
      setTimeout(() => {
        const zapierForm = this.$refs.zapier as HTMLElement
        const zapierScript = document.createElement('script')
        zapierScript.setAttribute('type', 'module')
        zapierScript.setAttribute('src', 'https://zapier.com/partner/embed/app-directory/wrapper.js?app=a2z-events&link-target=new-tab&theme=light&create-without-template=show&use-this-zap=show')
        zapierForm.appendChild(zapierScript)
      }, 300)
    }
  }

  closeModal () {
    this.$data.dialog = false
    setTimeout(() => {
      const zapierForm = this.$refs.zapier as HTMLElement
      if (zapierForm) {
        zapierForm.innerHTML = ''
      }
      Object.assign(this.$data, this.initialState()) // reset
      this.reloadData() // reload
    }, 500) // prevents modal from showing reset before fading out
  }

  showPushPull (direction: string): boolean {
    for (let i = 0; i < this.integrationCardInfo.length; i++) {
      if (this.integrationCardInfo[i].name === this.$data.currentIntegrationName) {
        return this.integrationCardInfo[i][direction].length > 0
      }
    }
    return false
  }

  showTypeOption (integrationType: string): boolean {
    for (let i = 0; i < this.integrationCardInfo.length; i++) {
      if (this.integrationCardInfo[i].name === this.$data.currentIntegrationName) {
        return this.integrationCardInfo[i][this.$data.push_or_pull.toLowerCase()].includes(integrationType)
      }
    }
    return false
  }

  async reloadData () {
    await this.$store.dispatch('syncs/getSyncs')
    await this.$store.dispatch('event/getParticipantFields', this.$route.params.event_uuid)
    await this.$store.dispatch('event/getSessionFields', this.$route.params.event_uuid)
  }

  get zapierKey () {
    return this.zapierKeys[0]?.api_key ?? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
  }

  get uniqueIdentifier () {
    switch (this.$data.integrationType.table) {
      case 'participants':
        return [
          {
            label: 'Email',
            value: 'email'
          },
          {
            label: 'External ID',
            value: 'external_id'
          }
        ]
      case 'sessions':
        return [
          {
            label: 'External ID',
            value: 'external_id'
          }
        ]
      case 'session_attendees':
      default:
        return [
          {
            label: 'Flag',
            value: 'flag'
          }
        ]
    }
  }

  modalNext () {
    if (this.$data.modalIndex < 3) {
      this.$data.modalIndex = this.$data.modalIndex + 1
    }
    if (this.$data.modalIndex === 2) {
      this.setIntegratrationType()
    }
    if (this.$data.modalIndex === 3) {
      this.$data.nextBtnTxt = 'Create'
    } else {
      this.$data.nextBtnTxt = 'Next'
    }
  }

  modalPrevious () {
    this.$data.modalIndex = this.$data.modalIndex - 1
    if (this.$data.modalIndex === 1) {
      // foolproofing
      this.$data.current_sync_data.type = null
      this.$data.integrationType = null
    }
    if (this.$data.modalIndex === 3) {
      this.$data.nextBtnTxt = 'Create'
    } else {
      this.$data.nextBtnTxt = 'Next'
    }
  }

  updatePushOrPull (value) {
    this.$data.push_or_pull = value
  }

  setIntegratrationType () {
    const integrationName = this.$data.currentIntegrationName.toLowerCase()
    const integrationTypesArr = Object.keys(this.$data.all_sync_hash[this.$data.push_or_pull][this.$data.table])
    for (let i = 0; i < integrationTypesArr.length; i++) {
      if (integrationTypesArr[i].includes(integrationName)) {
        this.$data.current_sync_data.type = integrationTypesArr[i]
        this.$data.integrationType = this.$data.all_sync_hash[this.$data.push_or_pull][this.$data.table][integrationTypesArr[i]]
      }
    }
  }

  handleEmail () {
    this.$data.sendWelcomeEmail = !this.$data.sendWelcomeEmail
    if (this.$data.sendWelcomeEmail === false) {
      this.$data.current_sync_data.send_welcome_email = 0
    } else if (this.$data.sendWelcomeEmail === true) {
      this.$data.current_sync_data.send_welcome_email = 1
    }
  }

  clearCurrentSyncFieldsAndSetCurrentSyncTable () {
    this.$data.current_sync_data.sync_fields = {}
    // this.$data.current_sync_data.table = this.$data.all_sync_hash[sync_type].table
    // this.$data.push_or_pull = this.$data.all_sync_hash[sync_type].push_or_pull
  }

  async submit () {
    const payload = {
      event_uuid: this.$route.params.event_uuid,
      data: this.$data.current_sync_data
    }
    const currentSyncType = payload.data.type
    payload.data.table = this.$data.table
    payload.data.type = this.$data.all_sync_hash[this.$data.push_or_pull][this.$data.table][currentSyncType].type
    try {
      await this.$store.dispatch('syncs/createSync', payload)
      Container.get(Notification).success('Sync successfully created.')
      this.$router.push({
        name: 'level-two.event.syncs' // this will be updated to route to edit sync
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  // Flagged for removal
  // get fieldItemsForSelectToUse () {
  //   switch (this.$data.current_sync_data.type) {
  //     case 'participants':
  //       return this.$data.participant_fields_items_for_select
  //     case 'sessions':
  //       return this.$data.session_fields_items_for_select
  //     default:
  //       return this.$data.participant_fields_items_for_select
  //   }
  // }

  // get sync_type_items_for_select () {
  //   return Object.keys(this.$data.all_sync_hash[this.$data.push_or_pull][this.$data.table])
  // }

  onErrorZapierKey () {
    Container.get(Notification).error('There was an error copying the zapier key. Please refresh and try again.')
  }

  onCopyZapierKey () {
    Container.get(Notification).success('Zapier key successfully copied.')
  }

  onErrorEventUuid () {
    Container.get(Notification).error('There was an error copying the event uuid. Please refresh and try again.')
  }

  onCopyEventUuid () {
    Container.get(Notification).success('Event uuid successfully copied.')
  }

  @Watch('syncs')
  onSyncChange (payload: any) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        this.$data.all_sync_hash[payload[i].push_or_pull][payload[i].table][payload[i].displayName] = payload[i]
      }
    }
  }

  @Watch('zapierKeys')
  async onZapierKeysChange (payload: any) {
    // if there are no keys, generate one.
    if (payload.length < 1) {
      await this.$store.dispatch('security/generateZapierKey')
    }
  }

  @Watch('participant_fields')
  onParticipantFieldsChange (payload) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        if (!this.excludedParticipantImportFields.includes(payload[i].field)) {
          this.$data.participant_fields_items_for_select.push({
            text: payload[i].label,
            value: payload[i].field
          })
        }
      }
    }
  }

  @Watch('session_fields')
  onSessionFieldsChange (payload) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        this.$data.session_fields_items_for_select.push({
          text: payload[i].label,
          value: payload[i].field
        })
      }
    }
  }
}
